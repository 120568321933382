<template>
    <div class="animation">
    <div id="snackbar"></div>          

    <!-- Manual Sync List -->
    <b-card>
       <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
         <div slot="ManualSyncID" slot-scope="props">
            <!-- <i class="fa fa-edit" @click="editProg(props.row.ManualSyncID)"></i> -->

            <img v-if="!synced" src="img/loading-blue.gif" width="30" height="30">
            <!-- <img v-if="!synced" src="img/loading-blue.gif"> -->
            <b-button v-if="synced" @click="syncData(props.row.ManualSyncID)" variant="info" block>Sync</b-button>
          </div>
        </v-client-table>
    </b-card>
    <!-- End List -->
</div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import MasterService from "@/services/MasterService";

export default {
  name: "ManualSync",
  components: {
    cSwitch,
    Event
  },
  data() {
    return {
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      synced: true,
      data: [],
      columns: ["Provider", "Cinema", "Movie", "Date", "ManualSyncID"],

      options: {
        headings: {
          Provider: "Provider",
          Cinema: "Cinema",
          Movie: "Movie",
          Date: "Date",
          ManualSyncID: "Sync"
        },
        sortable: ["Provider", "Cinema", "Movie", "Date"],
        filterable: ["Provider", "Cinema", "Movie", "Date"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getSyncList();
  },
  methods: {
    getSyncList: function() {
      this.data = [];
      MasterService.getSyncList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.SyncList.length > 0) {
            this.data = data.SyncList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    syncData: function(ManualSyncID) {
      this.synced = false;
      let payload = {
        ManualSyncID
      };
      MasterService.syncData(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.synced = true;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>

